<template>
  <Form ref="form"
        :show-error-message="false"
        scroll-to-error>
    <div v-for="(formItem, index) in fieldData"
         :key="index"
         class="form-group">
      <Cell v-if="formItem.title"
            :title="formItem.title"
            class="form-group-head" />
      <template v-for="item in formItem.data">
        <template v-if="item.visible !== false">
          <!-- calender -->
          <template v-if="item.type === 'calendar'">
            <CalendarCell v-model="value[item.key]"
                          v-bind="item.props"
                          :attrs="item.attrs"
                          :rules="rules[item.key]"
                          :key="item.key" />
          </template>

          <!-- datepicker -->
          <template v-else-if="item.type === 'datepicker'">
            <DatePickerCell v-model="value[item.key]"
                            v-bind="item.props"
                            :attrs="item.attrs"
                            :rules="rules[item.key]"
                            :key="item.key" />
          </template>

          <!-- select -->
          <template v-else-if="item.type === 'select'">
            <SelectCell v-model="value[item.key]"
                        v-bind="item.props"
                        v-on="item.listeners" 
                        :attrs="item.attrs"
                        :options="item.options"
                        :rules="rules[item.key]"
                        :key="item.key" />
          </template>

          <!-- switch -->
          <template v-else-if="item.type === 'switch'">
            <SwitchCell v-model="value[item.key]"
                        v-bind="item.props"
                        :attrs="item.attrs"
                        :rules="rules[item.key]"
                        :key="item.key" />
          </template>

          <!-- checkbox -->
          <template v-else-if="item.type === 'checkbox'">
            <CheckboxCell v-model="value[item.key]"
                          v-bind="item.props"
                          :attrs="item.attrs"
                          :rules="rules[item.key]"
                          :key="item.key" />
          </template>

          <!-- uploader -->
          <template v-else-if="item.type === 'uploader'">
            <Field v-bind="item.props"
                   v-on="item.listeners"
                   :value="String(value[item.key])"
                   :key="item.key"
                   :rules="rules[item.key]"
                   :input-align="item.props.inputAlign || 'right'">
              <template slot="input">
                <Uploader v-model="value[item.key]"
                          v-bind="item.props" />
              </template>
            </Field>
          </template>

          <template v-else>
            <Field v-bind="item.props"
                   v-on="item.listeners"
                   v-model="value[item.key]"
                   :key="item.key"
                   :rules="rules[item.key]"
                   :input-align="item.props.inputAlign || 'right'">
              <!-- 支持自定义插槽, 格式 [key]-[slotname] -->
              <template v-if="item.props.slot"
                        :slot="item.props.slot.split('-')[1]">
                <slot :name="item.props.slot" />
              </template>
            </Field>
          </template>
        </template>
      </template>
    </div>
  </Form>
</template>
<script>
import { Form, Cell, Toast } from 'vant'
import Field from './field'
import SelectCell from './selectCell'
import CalendarCell from './calendarCell'
import CheckboxCell from './checkboxCell'
import DatePickerCell from './datePickerCell'
import Uploader from '../uploader'
import SwitchCell from './switchCell'
export default {
  name: 'DynamicForm',
  components: {
    Form,
    Field,
    Cell,
    SelectCell,
    CalendarCell,
    Uploader,
    SwitchCell,
    CheckboxCell,
    DatePickerCell
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    rules: { // 表单校验规则
      type: Object,
      default: () => ({})
    },
    fieldData: { // 定义表单字段
      type: Array,
      default: () => []
    }
  },
  watch: {
    fieldData: {
      immediate: true,
      handler () {
        this.normalize()
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    validate () {
      return new Promise((resolve) => {
        this.$refs.form.validate()
          .then(() => {
            resolve(true)
          })
          .catch(err => {
            Toast(err && err[0] && err[0].message || '校验失败')
            resolve(false)
          })
      })
    },
    normalize () {
      const rules = this.rules
      this.fieldData.forEach(formItem => {
        formItem.data.forEach(item => {
          item.props = item.props || {}
          item.attrs = item.attrs || {}

          item.error = false

          if (item.label) {
            item.props.label = item.label
          }
          if (rules[item.key] && rules[item.key][0] && rules[item.key][0].required) {
            if (item.type !== 'text') {
              item.props.required = true
            }
          }
          if (item.type === 'select' && typeof item.options === 'function') {
            item.options = item.options()
          }
          if (item.direction === 'column') {
            item.props.direction = item.direction
            item.props.inputAlign = 'left'
          }
        })
      })
    },
    isDatePickerType (type) {
      return ['date', 'time', 'year-month', 'month-day', 'datehour', 'datetime'].includes(type)
    }
  }
}
</script>
<style lang="less" scoped>
@import "./field.less";
.form-group {
  margin-bottom: 16px;
  background-color: #fff;

  .form-group-head {
    padding: 0;
    padding-left: 24px;
    height: 100px;
    line-height: 100px;
    font-size: 28px;
    font-weight: bold;
  }
}
</style>
