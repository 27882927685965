<template>
  <div>
    <Filed
      v-bind="$attrs"
      :value="String(value)"
      :rules="rules"
      input-align="right"
      readonly
    >
      <template slot="input">
        <van-switch
          v-model="checked"
          v-bind="attrs"
          v-on="$listeners"
          :size="size"
        />
      </template>
    </Filed>
  </div>
</template>
<script>
import { Switch } from 'vant'
import Filed from './field'
export default {
  name: 'switchCell',
  components: {
    [Switch.name]: Switch,
    Filed
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    size: {
      type: [Number, String],
      default: '24'
    },
    attrs: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      checked: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.checked = value
      }
    },
    checked(value) {
      this.$emit('input', value)
    }
  }
}
</script>