<template>
  <div>
    <Filed
      v-bind="$attrs"
      :value="String(value)"
      :rules="rules"
      input-align="right"
      readonly
    >
      <template slot="input">
        <van-checkbox
          v-model="checked"
          v-bind="attrs"
          v-on="$listeners"
        >
          <template v-for="(n, slot) in $slots">
            <slot :name="slot" />
          </template>
        </van-checkbox>
      </template>
    </Filed>
  </div>
</template>
<script>
import { Checkbox } from 'vant'
import Filed from './field'
export default {
  name: 'switchCell',
  components: {
    [Checkbox.name]: Checkbox,
    Filed
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    attrs: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      checked: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.checked = value
      }
    },
    checked(value) {
      this.$emit('input', value)
    }
  }
}
</script>