<template>
  <div>
    <Filed v-bind="$attrs"
           :value="formatValueStr"
           :rules="rules"
           input-align="right"
           is-link
           readonly
           @click="onClickCell" />
    <Calendar v-model="show"
              v-bind="attrs"
              :type="type"
              :default-date="currentDate"
              @confirm="onConfirm">
      <template v-for="(n, slot) in $slots">
        <slot :name="slot" />
      </template>
    </Calendar>
  </div>
</template>
<script>
import { Calendar } from 'vant'
import Filed from './field'
export default {
  name: 'calendarCell',
  components: {
    Filed,
    Calendar
  },
  props: {
    value: { // 日期对象或日期对象数组
      type: [Date, Array],
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    },
    attrs: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      show: false,
      currentDate: null,
      formatValueStr: ''
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        if (value) {
          this.currentDate = value
        }
        this.formatValue()
      }
    }
  },
  computed: {
    type () {
      return this.attrs.type || 'single'
    }
  },
  methods: {
    formatValue () {
      let value
      switch (this.type) {
        case 'single':
          value = this.formatFullDate(this.currentDate)
          break
        case 'range':
          value = this.formatRange(this.currentDate)
          break
        case 'multiple':
          value = this.formatMultiple(this.currentDate)
      }
      this.formatValueStr = value
    },
    onClickCell () {
      this.show = true
    },
    onConfirm (value) {
      this.show = false
      this.$emit('input', value)
      this.$emit('confirm', value)
    },
    formatDate (date) {
      if (date) {
        return `${date.getMonth() + 1}/${date.getDate()}`;
      }
    },
    formatFullDate (date) {
      if (date) {
        return `${date.getFullYear()}/${this.formatDate(date)}`;
      }
    },
    formatRange (dateRange) {
      if (dateRange && dateRange.length) {
        const [start, end] = dateRange;
        return `${this.formatFullDate(start)} - ${this.formatFullDate(end)}`;
      }
    },
    formatMultiple (dateRange) {
      if (dateRange && dateRange.length) {
        return dateRange.map(date => this.formatFullDate(date)).toString()
      }
    }
  }
}
</script>