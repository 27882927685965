<template>
  <div class="uploader">
    <Uploader
      v-model="fileList"
      v-bind="$attrs"
      v-on="$listeners"
      :previewSize="previewSize"
      :beforeRead="beforeRead"
      :afterRead="afterRead"
      accept="*"
    >
      <slot>
        <div class="customBox" :style="{ width: previewSize, height: previewSize }"></div>
      </slot>
      </Uploader>
    <div v-if="tips" class="label">{{ tips }}</div>
  </div>
</template>
<script>
import { Uploader, Toast } from 'vant'
import uploaderUtils from '@/utils/oss'
export default {
  name: 'uploader',
  components: {
    Uploader
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    previewSize: {
      type: [Number, String],
      default: '80px'
    },
    maxSize: {
      type: Number,
      default: 50 * 1024 * 1024 // 50M
    },
    accept: {
      type: Array,
      default: () => ['.jpg', '.jpeg', '.png', '.pdf']
    },
    tips: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      fileList: []
    }
  },
  watch: {
    value (value) {
      this.fileList = value
    },
    fileList (value) {
      this.$emit('input', value)
    }
  },
  methods: {
    beforeRead(file) {
      if (this.maxSize && (file.size > this.maxSize)) {
        Toast(`单个附件最大不能超过${this.maxSize / 1024 / 1024}M，请重新选择`)
        return false
      }
      if (!this.accept.includes('.' + file.type.split('/')[1])) {
        Toast('请检查上传文件格式')
        return false
      }
      return true
    },
    afterRead(file) {
      file.status = 'uploading'
      file.message = '上传中...'
      uploaderUtils.uploader(uploaderUtils.filePath.path, [file.file]).then(res => {
        if (res[0].res.status === 200) {
          file.status = 'done'
          file.url = `${uploaderUtils.filePath.host}/${res[0].name}`
        } else {
          return Promise.reject()
        }
      }).catch(() => {
        file.status = 'failed'
        file.message = '上传失败'
      })
    }
  }
}
</script>
<style lang="less" scoped>
.uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;

  .label {
    line-height: 1;
    margin-top: 20px;
  }
}
</style>
<style lang="less">
.customBox {
  margin-top: 10px;
  position: relative;
  background: #ffffff;
  border: 1px dashed #cccccc;
  border-radius: 10px;
  box-sizing: border-box;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 4px;
    transform: translate3d(-50%, -50%, 0);
    background-color: #4a4a4a;
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 4px;
    height: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: #4a4a4a;
  }
}
</style>