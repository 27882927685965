<template>
  <div style="width: 100%;">
    <Filed
      v-bind="$attrs"
      :value="formatValueStr"
      :rules="rules"
      input-align="right"
      is-link
      readonly
      @click="onClickCell"
    />
    <Popup v-model="show" round position="bottom">
      <DatetimePicker
        v-model="currentDate"
        v-bind="attrs"
        :type="type"
        @cancel="onCancel"
        @confirm="onConfirm"
        @change="onChange"
      >
        <template v-for="(i, slot) in $slots" :slot="slot">
          <slot :name="slot" />
        </template>
      </DatetimePicker>
    </Popup>
  </div>
</template>
<script>
import { DatetimePicker, Popup } from 'vant'
import Filed from './field'

const formatter = (value, type = 'date') => {
  if (value) {
    const date = new Date(+value)
    const f = (n) => n > 10 ? n : '0' + n
    const year = date.getFullYear()
    const month = f(date.getMonth() + 1)
    const day = f(date.getDate())
    const hours = f(date.getHours())
    const minutes = f(date.getMinutes())

    switch(type) {
      case 'date':
        return `${year}/${month}/${day}`
      case 'time':
        return `${hours}:${minutes}`
      case 'year-month':
        return `${year}/${month}`
      case 'month-day':
        return `${month}/${day}`
      case 'datehour':
        return `${year}/${month}/${day} ${hours}`
      case 'datetime':
        return `${year}/${month}/${day} ${hours}:${minutes}`
    }
  }
  return value
}

export default {
  name: 'datePickerCell',
  components: {
    Filed,
    Popup,
    DatetimePicker
  },
  props: {
    value: {
      type: [Date],
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    },
    formatter: { // 格式化显示的数据
      type: Function,
      default: formatter
    },
    attrs: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      show: false,
      currentDate: new Date()
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          this.currentDate = value
        }
      }
    }
  },
  computed: {
    formatValueStr() {
      return formatter(this.value, this.type)
    },
    type() {
      return this.attrs.type || 'date'
    }
  },
  methods: {
    onClickCell() {
      this.show = true
    },
    onCancel() {
      if (Reflect.has(this.$listeners, 'cancel')) {
        this.$emit('cancel', () => {
          this.show = false
        })
      } else {
        this.show = false
      }
    },
    onConfirm(value) {
      this.$emit('input', this.formatValue(value))
      if (Reflect.has(this.$listeners, 'confirm')) {
        this.$emit('confirm', () => {
          this.show = false
        })
      } else {
        this.show = false
      }
    },
    formatValue(value) {
      if (value instanceof Date) {
        return value
      }
      if (this.type === 'time') {
        const date = new Date()
        const [hours, minutes] = value.split(':')
        date.setHours(hours, minutes)
        return date
      }
    },
    onChange(picker) {
      this.$emit('change', picker)
    }
  }
}
</script>