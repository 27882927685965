/* eslint-disable */
import { aliyunOSS } from '@/api/common';

const getClient = async () => {
  let res =  await aliyunOSS()
  const resCon = res.data
  const OSS = (await import('ali-oss')).default
  const client = new OSS({
    accessKeyId: resCon.AccessKeyId,
    accessKeySecret: resCon.AccessKeySecret,
    stsToken: resCon.SecurityToken,
    bucket: 'i-driven-images',
    region: 'cn-hangzhou',
    endpoint: 'https://oss-cn-hangzhou.aliyuncs.com'
  });
  return client
};

const filePath = {
  host: 'https://images.i-driven.com.cn/',
  path: 'eos'
};

const UUID = () => {
  const s = [];
  const hexDigits = "0123456789abcdef";
  for (let i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4";
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);

  s[8] = s[13] = s[18] = s[23] = "";
  let uuid = s.join("");
  return uuid;
};

const uploader = async (dir, files) => {
  const client = await getClient()
  const options = {
    meta: { year: 2019 },
    timeout: 60000
  }
  const filesRequest = files.map((file) => {
    let newName =  '/' + UUID()
    newName += file.name.substring(file.name.lastIndexOf('.'));
    return client.multipartUpload(dir + newName, file, options)
  })
  return Promise.all(filesRequest)
};

const downloader = (data, cb) => {
  const sendData = (client) => {
    const url = client.signatureUrl(data, {expires: 3600});
    cb(url);
  };
  getKey('', sendData);
};

const view = (prefix, cb) => {
  const cbParam = (client) => {
    const result = client.list({
      prefix: prefix
    });
    cb(result);
  };
  getKey('', cbParam);
};

const openDownloadWin = (url) => {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('id', 'camnpr');
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// 获取图片地址
const getUploadPicUrl = (uuid) => {
  return `${filePath.host}/${filePath.path}/${uuid}.png`
}


export default {
  uploader,
  downloader,
  view,
  openDownloadWin,
  filePath,
  getUploadPicUrl
}
